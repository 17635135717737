<template>
  <v-snackbar
    :top="isTop"
    :left="isLeft"
    :right="isRight"
    :bottom="isBottom"
    v-model="snackbarOpen"
    :color="color"
    :timeout="snackbarTimeout"
    v-if="snackbarOpen"
  >
    <template v-if="icon">
      <v-icon left color="white">{{icon}}</v-icon>
    {{ text }}
    <v-btn text fab @click.capture.prevent="setOpen(false)">
      <v-icon>close</v-icon>
    </v-btn>
    </template>
    <template v-else>
    {{ text }}
    <v-btn text fab @click.capture.prevent="setOpen(false)">
      <v-icon>close</v-icon>
    </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'Snackbar',
  computed: {
    snackbarOpen: {
      set (open) {
        return this.setOpen(open)
      },
      get () {
        return this.open
      }
    },
    snackbarTimeout: {
      set (miliseconds) {
        return this.setTimeout(miliseconds)
      },
      get () {
        return this.timeout
      }
    },
    ...mapGetters('Snackbar', [
      'open',
      'text',
      'timeout',
      'x',
      'y',
      'color',
      'icon'
    ]),
    isTop () {
      return this.y === 'top'
    },
    isBottom () {
      return this.y === 'bottom'
    },
    isLeft () {
      return this.x === 'left'
    },
    isRight () {
      return this.x === 'right'
    }
  },
  methods: {
    ...mapActions('Snackbar', [
      'setOpen',
      'setTimeout'
    ])
  }
}
</script>
